@import "src/styles/table";

.box-number {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .numb {
    width: calc(25% - 5px);
    padding: 0;
    border: 1px solid #f2f2f2;
    margin-bottom: 5px;
  }

  .space {
    flex-grow: 1;
  }

  .bg-red {
    border: 1px solid #e3c8c8;
  }

  .bg-green {
    border: 1px solid #bce7b9;
  }
}

.textBox {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  resize: none;
  height: 184px;
  font-size: 0.8rem;
}

.itemCell {
  width: 30%;
}

.valueCell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.badgeCell {
  width: 95%;
}

.activateCell {
  display: flex;
  justify-content: flex-end;
  width: 5%;
}

@media (max-width: 998px) {
  .box-number {
    .numb {
      width: calc(50% - 5px);
    }
  }
}


.box-fail {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
