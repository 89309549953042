@import "src/styles/table";

$activateCellWidthForMobile: 80px;
$smsFeeCellWidthForMobile: 80px;
$smsCountCellWidthForMobile: 80px;
$taxRateCellWidthForMobile: 80px;
$taxCellWidthForMobile: 80px;
$amountCellWidthForMobile: 80px;
$funcCellWidthForMobile: 80px;

$scrollTableWidthForMobile: $activateCellWidthForMobile + $smsFeeCellWidthForMobile + $smsCountCellWidthForMobile + $taxRateCellWidthForMobile + $taxCellWidthForMobile + $amountCellWidthForMobile + $funcCellWidthForMobile;

.activateCell {
  width: 15%;
}

.smsFeeCell {
  width: 15%;
}

.smsCountCell {
  width: 15%;
}

.taxRateCell {
  width: 15%;
}

.taxCell {
  width: 15%;
}

.amountCell {
  width: 15%;
}

.funcCell {
  width: 10%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .smsFeeCell {
    width: $smsFeeCellWidthForMobile;
  }

  .smsCountCell {
    width: $smsCountCellWidthForMobile;
  }

  .taxRateCell {
    width: $taxRateCellWidthForMobile;
  }

  .taxCell {
    width: $taxCellWidthForMobile;
  }

  .amountCell {
    width: $amountCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}