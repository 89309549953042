@import "src/styles/table";

.tableUserInfo {
  .colLabel {
    width: 20%;
  }

  .colInput {
    width: 30%;
  }

  .colSpace {
    padding: 0 10px 0 10px;
    flex-grow: 1;
  }

  .colPeriodPicker {
    width: 50%;
  }

  .colBtn {
    width: 100%;
    text-align: right;
  }
}
