@import "src/styles/table";

$statusCellWidthForMobile: 80px;
$dateCellWidthForMobile: 150px;
$memoCellWidthForMobile: 150px;
$sendCellWidthForMobile: 100px;
$messageCellWidthForMobile: 100px;

$scrollTableWidthForMobile: $statusCellWidthForMobile + $dateCellWidthForMobile + $memoCellWidthForMobile + $sendCellWidthForMobile + $messageCellWidthForMobile;

.box-number {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .numb {
    width: calc(25% - 5px);
    padding: 0;
    border: 1px solid #f2f2f2;
    margin-bottom: 5px;
  }

  .space {
    flex-grow: 1;
  }

  .bg-red {
    border: 1px solid #e3c8c8;
  }

  .bg-green {
    border: 1px solid #bce7b9;
  }
}

.apiSelect {
  width: 100%;
  padding: 3px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  font-size: 0.85rem;
}

.statusCell {
  width: 15%;
}

.dateCell {
  width: 20%;
}

.memoCell {
  width: 40%;
}

.sendCell {
  width: 10%;
}

.messageCell {
  width: 15%;
}


@media (max-width: 998px) {
  .box-number {
    .numb {
      width: calc(50% - 5px);
    }
  }

  .apiSelect {
    margin-bottom: 15px;
  }

  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .dateCell {
    width: $dateCellWidthForMobile;
  }

  .memoCell {
    width: $memoCellWidthForMobile;
  }

  .sendCell {
    width: $sendCellWidthForMobile;
  }

  .messageCell {
    width: $messageCellWidthForMobile;
  }
}

.box-fail {
  height: 100px;
  line-height: 100px;
  text-align: center;
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}