@import "../../../../styles/colors";
@import "../../../../styles/webSizing";

.box-join {
  width: 90%;
  max-width: 450px;
  height: 495px;
  margin: 0 auto 20px auto;
  background: white;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.item {
  font-size: 0.8rem;
  margin-top: 15px;
  margin-bottom: 5px;
}

