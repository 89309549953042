$border-color: #e7e7e7;
$main-color: #0db3e6;
$background: #e6e8ef;

$title-color: #a4a4a4;
$desc-color: #b5b5b5;
$kakao-color: #ffe812;
$hover-color: rgba(225, 255, 247, 1);
$content-color: #929292;
$header-color: black;
$info-color: #9c9c9c;
