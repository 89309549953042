@import "src/styles/table";

.itemCell {
  width: 15%;
}

.valueCell {
  width: 85%;
}

@media (max-width: 998px) {
  .itemCell {
    width: 30%;
  }

  .valueCell {
    width: 70%;
  }
}