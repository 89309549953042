@import "src/styles/table";

$chartWidth: 100%;
$chartBrowserHeight: 500px;
$chartMobileHeight: 220px;

.fullContainer {
  width: 100%;
  display: flex;
}

.halfContainer {
  width: 50%;
}

.chart {
  width: $chartWidth;
  height: $chartBrowserHeight;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleCell {
  width: 100px
}

.valueCell {
  width: calc(100% - 100px)
}

@media (max-width: 998px) {
  .fullContainer {
    flex-direction: column;
  }

  .halfContainer {
    width: 100%;
  }

  .chart {
    height: $chartMobileHeight;
  }
}

:export {
  chartWidth: $chartWidth;
  chartBrowserHeight: $chartBrowserHeight;
  chartMobileHeight: $chartMobileHeight;
}