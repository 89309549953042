@import "src/styles/table";

$numCellWidthForMobile: 80px;
$activateCellWidthForMobile: 80px;
$tpsCellWidthForMobile: 60px;
$nameCellWidthForMobile: 150px;
$typeCellWidthForMobile: 100px;
$sendAPICellWidthForMobile: 80px;
$moduleAPICellWidthForMobile: 80px;

$scrollTableWidthForMobile: $numCellWidthForMobile + $activateCellWidthForMobile + $tpsCellWidthForMobile + $nameCellWidthForMobile + $typeCellWidthForMobile + $sendAPICellWidthForMobile + $moduleAPICellWidthForMobile;

.numCell {
  width: 10%;
}

.activateCell {
  width: 10%;
}

.tpsCell {
  width: 10%;
}

.nameCell {
  width: 35%;
}

.typeCell {
  width: 15%;
}

.sendAPICell {
  width: 10%;
}

.moduleAPICell {
  width: 10%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .numCell {
    width: $numCellWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .tpsCell {
    width: $tpsCellWidthForMobile;
  }

  .nameCell {
    width: $nameCellWidthForMobile;
  }

  .typeCell {
    width: $typeCellWidthForMobile;
  }

  .sendAPICell {
    width: $sendAPICellWidthForMobile;
  }

  .moduleAPICell {
    width: $moduleAPICellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}