@import '../../../styles/colors';

.header {
  height: 50px;
  display: flex;
  border-bottom: 1px solid $border-color;

  div {
    font-size: 1.2rem;
    flex-grow: 1;
    padding-left: 10px;
    line-height: 50px;
    color: #6363db;
  }

  svg {
    margin-top: 13px;
    margin-right: 10px;
    color: #a8a8a8;

    &:hover {
      color: #6363db;
    }
  }
}
