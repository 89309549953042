@import "src/styles/table";

$activateCellWidthForMobile: 80px;
$distributionCellWidthForMobile: 150px;
$nameCellWidthForMobile: 100px;
$priceCellWidthForMobile: 100px;
$funcCellWidthForMobile: 70px;

$scrollTableWidthForMobile: $activateCellWidthForMobile + $distributionCellWidthForMobile + $nameCellWidthForMobile + $priceCellWidthForMobile + $funcCellWidthForMobile;

.activateCell {
  width: 5%;
}

.testCell {
  width: 5%;
}

.apiCell {
  width: 15%;
}

.distributionCell {
  width: 25%;
}

.nameCell {
  width: 30%;
}

.priceCell {
  width: 15%;
}

.funcCell {
  width: 5%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .distributionCell {
    width: $distributionCellWidthForMobile;
  }

  .nameCell {
    width: $nameCellWidthForMobile;
  }

  .priceCell {
    width: $priceCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }

  .hiddenCell {
    display: none;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}