@import "src/styles/table";

.table {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 30%;
  }

  .col-space {
    flex-grow: 1;
  }
}

@media (max-width: 998px) {
  .col-label {
    width: 48.5% !important;
  }

  .col-value {
    width: 51.5% !important;
  }

}