$height: 60px;


.appbar {
  background: rgba(0, 0, 0, 0.5)!important;

  .space {
    flex-grow: 1;
  }

  .btn {
    color: #ffffff;
  }
}

.nav {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: $height;
  display: flex;
  overflow: hidden;
  line-height: $height;
  padding: 0 20px 0 20px;
  background: rgba(0, 0, 0, 0.5);

  .logo {
    font-size: 1.4rem;
    font-weight: 900;
    color: white;
  }

  .spacer {
    flex-grow: 1;
  }
}

.nav-back {
  width: 100vw;
  height: 60px;
  top: -60px;
  left: 0;
  position: fixed;
  z-index: -1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.8);
}


.btn-menu {
  color: #ffffff;
  cursor: pointer;

  svg {
    font-size: 35px;
    padding: 5px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
  }
}

.cont-drawer {
  height: 100%;
  background: #ececec;
  width: 50vw;

  .btn-drawer {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
  }
}


