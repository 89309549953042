@import "src/styles/table";

.cont-list {
  display: flex;

  > div {
    padding: 5px 15px 5px 15px;

    svg {
      width: 20px;
      margin-top: -4px;
      margin-right: 5px;
    }
  }

  .sent-at {
    color: #9a9a9a;
    padding: 5px 0 5px 0;
  }

  .cont-data {
    width: 95px;
  }

  .cont-test {
    width: 390px;
    display: flex;
    flex-wrap: wrap;

    .box-test {
      width: 120px;

      .test {
        margin-bottom: 10px;

        .logo {
          width: 13px;
          height: 13px;
          margin-right: 10px;
        }
      }
    }
  }
}
