@import "src/styles/table";

$idCellWidthForMobile: 25px;
$activateCellWidthForMobile: 70px;
$badgeCellWidthForMobile: 60px;
$nameCellWidthForMobile: 150px;
$keyCellWidthForMobile: 300px;
$funcCellWidthForMobile: 70px;

$scrollTableWidthForMobile: $idCellWidthForMobile + $activateCellWidthForMobile + $badgeCellWidthForMobile + $nameCellWidthForMobile + $keyCellWidthForMobile + $funcCellWidthForMobile;

.activateCell {
  width: 15%;
}

.carrierCell {
  width: 10%;
}

.numberCell {
  width: 65%;
}

.funcCell {
  width: 10%;
}

.idCell {
  width: 10%;
}

.badgeCell {
  width: 10%;
}

.nameCell {
  width: 20%;
}

.keyCell {
  width: 40%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .carrierCell {
    width: 50px;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .numberCell {
    width: 120px;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }

  .idCell {
    width: $idCellWidthForMobile;
  }

  .badgeCell {
    width: $badgeCellWidthForMobile;
  }

  .nameCell {
    width: $nameCellWidthForMobile;
  }

  .keyCell {
    width: $keyCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}
