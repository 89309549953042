@import "src/styles/table";

$numCellWidthForMobile: 60px;
$activateCellWidthForMobile: 60px;
$emailCellWidthForMobile: 280px;
$pointCellWidthForMobile: 100px;
$apiCellWidthForMobile: 100px;

$scrollTableWidthForMobile: $numCellWidthForMobile + $activateCellWidthForMobile + $emailCellWidthForMobile + $pointCellWidthForMobile + $apiCellWidthForMobile;

.input-label {
  margin-top: 20px;
  margin-bottom: 5px;
  color: #7e7e7e;
}

.numCell {
  width: 8%;
}

.activateCell {
  width: 8%;
}

.emailCell {
  width: 60%;
}

.pointCell {
  width: 12%;
}

.apiCell {
  width: 12%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .numCell {
    width: $numCellWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .emailCell {
    width: $emailCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMobile;
  }

  .apiCell {
    width: $apiCellWidthForMobile;
  }
};

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}