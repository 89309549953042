@import "src/styles/table";

.tableStatus {
  .colLabel {
    width: 20%;
  }

  .colValue {
    width: 80%;
    text-align: left;
  }

  .colProgressBar {
    padding: 0 20px 0 0;
    width: 60%;
  }

  .colDesc {
    width: 20%;
  }
}
