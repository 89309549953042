.fullContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px auto;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  background: #ffffff;
  padding: 15px;
}

.fullRow {
  width: 100%;
  flex-direction: column;
}

.titleLeft {
  width: 90%;
  justify-content: flex-start;
}

.titleRight {
  width: 10%;
  justify-content: flex-end;
}

@media (max-width: 998px) {
  .titleLeft {
    width: 60%;
  }

  .titleRight {
    width: 40%;
  }
}