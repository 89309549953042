@import "../../../../styles/table";

.tableStatus {
    .colLabel {
        width: 20%;
    }

    .colValue {
        text-align: center;
        width: 80%;
    }


    .colProgressBar {
        width: 60%;
    }

    .colDesc {
        text-align: center;
        width: 20%;
    }
}
