@import "src/styles/colors";

.cont {
  display: flex;
  border-top: 1px solid $border-color;
  padding: 5px;
  margin-bottom: 20px;

  .box-time {
    display: flex;
    width: 15%;

    .prog {
      display: flex;
      align-items: center;
      width: 50px;
      margin-right: 10px;
      padding-top: 5px;
    }

    .times {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .request-at {
        font-size: 1.1rem;
        color: #9a9ab1;
      }

      .icons {
        svg {
          margin-top: -5px;
          margin-left: -1px;
          width: 20px !important;
          color: #929292;
          margin-right: 4px;
        }

        span {
          color: #606060;
        }
      }
    }
  }

  .box-count {
    width: 15%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .total {
      margin-bottom: 2px;
      svg {
        margin-right: 10px;
        color: #bbbbd9;
        width: 20px;
      }

      font-size: 1.1rem;
      color: #4646a2;
    }

    .result {
      font-size: 0.8rem;
      color: #7c7c7c;

      span {
        margin-left: 10px;
      }

      svg {
        width: 15px;
      }
    }
  }

  .box-user {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8rem;
    line-height: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .value {
      color: #7c7c7c;
      margin-left: 10px;
    }
  }


  .box-msg {
    width: 30%;
    font-size: 0.9rem;
    line-height: 17px;
    color: #5b5b5b;
    text-wrap: normal;
  }
}

.icon-ok {
  color: green;
  border-radius: 25px;
  border: 2px solid green;
  width: 90px;
  height: 90px;
}

.circleProgress {
  width: 50px;
  height: 50px;
}

@media (max-width: 998px) {
  .cont {
    flex-wrap: wrap;

    .box-time {
      width: 60%;
      margin-bottom: 10px;

      .times {
        .request-at {
          font-size: 1rem;
        }
      }
    }

    .box-count {
      width: 40%;
      margin-bottom: 10px;
    }

    .box-user {
      width: 100%;
      margin-bottom: 10px;
    }

    .box-msg {
      width: 100%;
    }
  }
}