@import "src/styles/table";

$emailCellWidthForMobile: 150px;
$amountCellWidthForMobile: 80px;
$taxRateCellWidthForMobile: 80px;
$taxAmountCellWidthForMobile: 80px;
$smsCountCellWidthForMobile: 80px;
$smsFeeCellWidthForMobile: 80px;
$originFeeCellWidthForMobile: 80px;
$profitCellWidthForMobile: 80px;

$scrollTableWidthForMobile: $emailCellWidthForMobile + $taxRateCellWidthForMobile + $smsFeeCellWidthForMobile + $originFeeCellWidthForMobile + $amountCellWidthForMobile + $taxAmountCellWidthForMobile + $smsCountCellWidthForMobile + $profitCellWidthForMobile;
$sumCellWidthForMobile: $emailCellWidthForMobile + $taxRateCellWidthForMobile + $smsFeeCellWidthForMobile + $originFeeCellWidthForMobile;

.emailCell {
  width: 30%;
}

.amountCell {
  width: 10%;
}

.taxRateCell {
  width: 10%;
}

.taxAmountCell {
  width: 10%;
}

.smsCountCell {
  width: 10%;
}

.smsFeeCell {
  width: 10%;
}

.originFeeCell {
  width: 10%;
}

.profitCell {
  width: 10%;
}

.sumCell {
  width: 60%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .emailCell {
    width: $emailCellWidthForMobile;
  }

  .amountCell {
    width: $amountCellWidthForMobile;
  }

  .taxRateCell {
    width: $taxRateCellWidthForMobile;
  }

  .taxAmountCell {
    width: $taxAmountCellWidthForMobile;
  }

  .smsCountCell {
    width: $smsCountCellWidthForMobile;
  }

  .smsFeeCell {
    width: $smsFeeCellWidthForMobile;
  }

  .originFeeCell {
    width: $originFeeCellWidthForMobile;
  }

  .profitCell {
    width: $profitCellWidthForMobile;
  }

  .sumCell {
    width: $sumCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}