@import "src/styles/table";

$idCellWidthForMobile: 80px;
$nameCellWidthForMobile: 80px;
$activateCellWidthForMobile: 100px;
$devCellWidthForMobile: 100px;
$apiCellWidthForMobile: 150px;

$scrollTableWidthForMobile: $idCellWidthForMobile + $nameCellWidthForMobile + $activateCellWidthForMobile + $devCellWidthForMobile + $apiCellWidthForMobile;

.table-apikey {
  .key {
    padding-top: 7px;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
}

.idCell {
  width: 10%;
}

.nameCell {
  width: 10%;
}

.activateCell {
  width: 10%;
}

.devCell {
  width: 10%;
}

.apiCell {
  width: 60%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .idCell {
    width: $idCellWidthForMobile;
  }

  .nameCell {
    width: $nameCellWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .apiCell {
    width: $apiCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}