.cont {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .box {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}
