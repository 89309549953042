@import "src/styles/table";

$requestedAtCellWidthForMobile: 70px;
$statusCellWidthForMobile: 70px;
$clientEmailCellWidthForMobile: 180px;
$pointCellWidthForMobile: 100px;
$amountCellWidthForMobile: 100px;
$taxCellWidthForMobile: 100px;
$countCellWidthForMobile: 100px;
$funcCellWidthForMobile: 80px;

$scrollTableWidthForMobile: $requestedAtCellWidthForMobile + $statusCellWidthForMobile + $clientEmailCellWidthForMobile + $pointCellWidthForMobile + $amountCellWidthForMobile + $taxCellWidthForMobile + $countCellWidthForMobile + $funcCellWidthForMobile;

.requestedAtCell {
  width: 10%;
}

.statusCell {
  width: 10%;
}

.clientEmailCell {
  width: 20%;
}

.pointCell {
  width: 10%;
}

.amountCell {
  width: 10%;
}

.taxCell {
  width: 10%;
}

.countCell {
  width: 10%;
}

.funcCell {
  width: 20%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .requestedAtCell {
    width: $requestedAtCellWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .clientEmailCell {
    width: $clientEmailCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMobile;
  }

  .amountCell {
    width: $amountCellWidthForMobile;
  }

  .taxCell {
    width: $taxCellWidthForMobile;
  }

  .countCell {
    width: $countCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}