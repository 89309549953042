@import "src/styles/table";

$statusCellWidthForMobile: 50px;
$dateCellWidthForMobile: 150px;
$memoCellWidthForMobile: 200px;
$sendCellWidthForMobile: 100px;
$messageCellWidthForMobile: 200px;

$scrollTableWidthForMobile: $statusCellWidthForMobile + $dateCellWidthForMobile + $memoCellWidthForMobile + $sendCellWidthForMobile + $messageCellWidthForMobile;

.statusCell {
  width: 10%;
}

.dateCell {
  width: 10%;
}

.memoCell {
  width: 50%;
}

.sendCell {
  width: 10%;
}

.messageCell {
  width: 20%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .dateCell {
    width: $dateCellWidthForMobile;
  }

  .memoCell {
    width: $memoCellWidthForMobile;
  }

  .sendCell {
    width: $sendCellWidthForMobile;
  }

  .messageCell {
    width: $messageCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}