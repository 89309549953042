.cont {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.flex {
  display: flex;
}

.space {
  flex-grow: 1;
}
