@import "src/styles/table";

$activateCellWidthForMobile: 80px;
$idCellWidthForMobile: 80px;
$nameCellWidthForMobile: 200px;
$funcCellWidthForMobile: 80px;

$scrollTableWidthForMobile: $activateCellWidthForMobile + $idCellWidthForMobile + $nameCellWidthForMobile + $funcCellWidthForMobile;

.activateCell {
  width: 20%;
}

.idCell {
  width: 33%;
}

.nameCell {
  width: 34%;
}

.funcCell {
  width: 13%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .idCell {
    width: $idCellWidthForMobile;
  }

  .nameCell {
    width: $nameCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}
