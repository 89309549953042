@import "src/styles/table";

.tableList {
  .colRequestId {
    width: 20%;
  }

  .colCreatedAt {
    width: 20%;
  }

  .colParams {
    width: 50%;
  }
  .colDown {
    width: 10%;
  }
}
