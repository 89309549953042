@import "src/styles/table";

.tableBalanceList {
  .colId {
    width: 15%;
  }

  .colSendType {
    width: 15%;
  }

  .colUsername {
    width: 15%;
  }

  .colPoint {
    width: 20%;
  }

  .colCurrency {
    width: 15%;
  }

  .colUpdatedAt {
    width: 20%;
  }
}
